<template>
  <div class="main-wrapper">
    <!-- <section class="main_banner_area_winapp text-white" style="min-height: 100px">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="info">
              <h1 class="display-4 mb-3 bold">
                <i class="icon-docs"></i> Biosoft On Windows
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section>
      <div class="container" style="padding: 90px 0;">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-7 col-md-6" style="margin-left: 20px;">
              <div class="how-it-single-module" >
                <h4 style="color: #636d75;">
                  <i class="icon-docs"></i> Biosoft On Windows
                </h4>
                <div
                  class="line-title-center"
                  style="margin-left: inherit;"
                ></div>
                <p>
                  สามารถกำหนดรหัสพนักงานและรหัสบัตรรูดหรือสแกนลายนิ้ว
                  ได้เหมือนหรือต่างกันก็ได้ สามารถคำนวณ
                  ระยะเวลาวันผ่านการทดลองงานได้ ทั้ง เป็น วัน เดือน และปี
                  และคำนวณวันที่ผ่าน การทดลองงานได้อัตโนมัติ
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-5 col-md-6" style="margin-top: auto;margin-left: -87px;">
              <div class="app-img">
                <carousel
                  :autoplay="true"
                  :responsive="{
                    0: { items: 1, nav: false },
                    600: { items: 1, nav: false },
                  }"
                >
                  <img v-for="(img, index) in images" :src="img" :key="index" />
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="clients_testimonial_area bg-light" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="section-heading text-center">
              <h2>HPS INTELLIGENCE Co., Ltd.</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="slider slider-for">
              <!-- Client testimonial Text  -->
              <div class="client-testimonial-text text-center">
                <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <div class="client-description text-center">
                  <p style="color: #636d75; font-size: 18px;">
                    <span
                      style="font-weight: bold;color: #41c9a2;font-size: 25px;"
                      >เพราะความสำเร็จของท่าน... คือความสำเร็จของเรา</span
                    >
                    เป็นวิสัยทัศน์ที่เรายึดถือมากว่า 15 ปี
                    ด้วยความมุ่งมันตั้งใจในการนำเสนอ Solution งานด้าน HR
                    เราจึงร่วมกันผลิตและพัฒนาซอฟแวร์ Biosoft
                    โดยกลุ่มนักพัฒนาซอฟต์แวร์ชาวไทยในนามบริษัท
                    <span style="font-weight: bold;color: #41c9a2;"
                      >บริษัท เอชพีเอส อินเทลลิเจนซ์ จำกัด</span
                    >
                  </p>
                </div>
                <div
                  class="client-description text-center"
                  style="padding-top: 50px"
                >
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #41c9a2;"
                      >เรามีลูกค้ากว่า 400 บริษัท</span
                    >
                    ที่ไว้วางใจให้ Biosoft
                    ร่วมเป็นส่วนหนึ่งในการขับเคลื่อนงานด้านทรัพยากรบุคคล
                    ซึ่งครอบคลุมในหลากหลายประเภทธุระกิจ และอุตสาหกรรม
                    เช่นการผลิตแปรรูปอาหาร การผลิตชิ้นส่วนอะไหล่ยานยนต์
                    การผลิตบรรจุภัณท์ธุรกิจการให้บริการอสังหาริมทรัพย์
                    การบริหารงานก่อสร้าง และอีกหลากหลายธุรกิจที่เราชาว Biosoft
                    ภาคภูมิใจ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-lg-5 col-md-6 mr-md-auto"
            style="padding-top: 80px;"
          >
            <div class="text-center">
              <div class="section-heading mb-4">
                <h2>" YOUR SUCCESS IS OUR SUCCESS "</h2>
              </div>
              <p style="font-size: 18px;">
                เพราะเราตระหนักดีว่าลูกค้าต้องการ
                <span style="font-weight: bold;color: #41c9a2;font-size: 25px;"
                  >ความสำเร็จ</span
                >
                ในฐานะผู้ช่วยลูกค้า
                เรามีหน้าที่ประสานขั้นตอนการทำงานของลูกค้าให้มีประสิทธิภาพ
                และสะดวกสบายอย่างสูงสุดเพราะความสำเร็จของลูกค้า
                คือเป้าหมายของเรา
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-sm-12">
            <div class="feature-img text-center">
              <v-lazy-image
                src="assets/images/windows/WindowsBase.jpg"
                alt="About Biosoft"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const VLazyImage = () => import("v-lazy-image");
const carousel = () => import("vue-owl-carousel");

export default {
  name: "about",
  components: {
    VLazyImage,
    carousel,
  },
  data: function() {
    return {
      images: [
        "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/web9.png",
        "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/web10.png",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/web11.png",
      ],
    };
  },
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 3s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
.how-it-single-module {
  padding: 40px;
    margin-bottom: 30px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
            box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
</style>
